/*.LoaderButton .spinning.glyphicon .lds-dual-ring {*/
    /*margin-right: 7px;*/
    /*top: 2px;*/
    /*animation: spin 1s infinite linear;*/
/*}*/
/*@keyframes spin {*/
    /*from { transform: scale(1) rotate(0deg); }*/
    /*to { transform: scale(1) rotate(360deg); }*/
/*}*/

.lds-dual-ring {
    display: inline-block;
    width: 24px;
    height: 24px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 18px;
    height: 18px;
    margin: 1px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
