/*! `Darkster` Bootstrap 4 theme */
@import url(https://fonts.googleapis.com/css?family=Comfortaa:200,300,400,700);
$headings-font-family:Comfortaa;

//$enable-grid-classes:false;
$primary:#FF304F;
$secondary:#2d3035;
$success:#00A86B;
//$danger:#FF304F;
//$info:#0F81C7;
//$warning:#0DE2EA;
//$light:#2d3035;
//$dark:#2d3035;
//$enable-shadows:true;
$body-color:#D2CFCA;
//$link-color:#f0f0f0;
//$font-size-base:1.1rem;
//$input-disabled-bg: #ccc;

/*! Import Bootstrap 4 variables */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
$body-bg:#202428;

//$link-hover-color:darken($link-color,20%);
//$navbar-dark-hover-color:$white;
//$navbar-light-hover-color:$gray-800;
//$navbar-light-active-color:$gray-800;
//$input-bg:$gray-300;

$pagination-bg:$secondary;

//$card-border-color:rgba($black, 0.6);
//$card-cap-bg:lighten($gray-800, 10%);

$card-bg:$secondary;
$table-head-bg:$primary;
$table-head-color:$body-color;
$table-bg:$secondary;

$list-group-bg:$secondary;
//$list-group-color:$body-color;
//$list-group-border-color:rgba($black,0.6);
//$list-group-hover-bg:lighten($body-bg,10%);
//$list-group-active-color:$white;
//$list-group-active-bg:$list-group-hover-bg;
//$list-group-active-border-color:$list-group-border-color;
//$list-group-disabled-color:$gray-800;
//$list-group-disabled-bg:$black;
//$list-group-action-color:$white;
@import '~bootstrap/scss/bootstrap.scss';

// Add SASS theme customizations here..
//.navbar-dark.bg-primary {background-color:#111111 !important;}